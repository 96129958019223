import React from "react";
import styles from "./Header.module.scss"; // Import styles from the SCSS module
import logo from "../../assets/SPRY-logo.svg"; // Import the logo image
import clevertap from "clevertap-web-sdk";

const Header = ({ onExploreClick }) => {
  return (
    <header className={styles.headerContainer}>
      <img
        src={logo}
        alt="Company Logo"
        className={styles.companyLogo}
        onClick={() => {
          clevertap.event.push(
            "quick_capture_app_Return_To_AI_Corner"
          );
          window.location.href = "https://www.sprypt.com/ai-corner";
        }}
      />
      <button
        className={styles.exploreButton}
        onClick={() => {
          clevertap.event.push(
            "quick_capture_app_Explore_Other_Features_Clicked"
          );
          window.location.href = "https://www.sprypt.com/ai-corner";
        }}
      >
        Explore other features
      </button>
    </header>
  );
};

export default Header;
