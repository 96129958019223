import { Modal } from "@material-ui/core";
import React, { useEffect } from "react";
import styles from "./CameraAccess.module.scss";
import cameraIcon from "../../assets/cameraIcon.svg";
import info from "../../assets/InofIcon.svg";
import close from "../../assets/close.svg";
import clevertap from "clevertap-web-sdk";

const CameraAccess = ({
  openCameraAccess,
  closeCameraAccess,
  allowedCameraAccess,
}) => {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
    closeCameraAccess();
  };
  const handleOpen = () => {};

  const requestCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      // Permission granted. If you want, you can show the camera stream using a <video> element.
      stream.getTracks().forEach((track) => track.stop()); // Stop the camera stream immediately since we just wanted the permission.
      clevertap.event.push("quick_capture_app_camera_access_allowed");
      allowedCameraAccess();
      handleClose();
    } catch (error) {
      console.error("Camera permission denied", error);
      // Handle the error or show a message to the user.
    }
  };

  useEffect(() => {
    if (openCameraAccess) {
      setOpen(true);
    }
  }, [openCameraAccess]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={styles.CameraAccessPopUpContainer}
      disableEscapeKeyDown={true}
      hideBackdrop={true}
    >
      <div className="bg-white rounded-[20px] max-w-[570px] py-5 w-full flex justify-between flex-col items-center outline-none">
        <div className="w-full pr-5 flex justify-end">
          <img
            src={close}
            alt=""
            className="w-[14px] h-[14px] flex justify-end cursor-pointer"
            onClick={handleClose}
          />
        </div>
        <div className="flex items-center gap-3">
          <div className="w-[50px] h-[50px] bg-[#E2E0FF] rounded-[50%] flex items-center justify-center">
            <img src={cameraIcon} alt="" className="" />
          </div>
          <div className={`${styles.header}`}>Camera Access Required</div>
        </div>
        <div className={`mt-7 text-center mb-7 ${styles.intro}`}>
          Grant access to the camera for capturing your insurance card.
        </div>
        <div className={`flex gap-5 ${styles.actions}`}>
          {/* <button className={`${styles.deny} py-4 px-5 min-w-[172px]`}>
            Deny
          </button> */}
          <button
            className={`${styles.allow} py-4 px-5 min-w-[172px]`}
            onClick={requestCameraPermission}
          >
            Allow
          </button>
        </div>
        <div className="flex justify-center">
          <div className="rounded-xl border px-6 py-3 mt-5 flex items-center gap-2 bg-[#F7F7F7] border-[#A1A1A1]">
            <img src={info} alt="" />
            <div className="opacity-60">
            We're fully HIPAA compliant, safeguarding your privacy and sensitive data at every step.
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CameraAccess;
