import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Styles from "./InsuranceForm.module.scss";

const InsuranceForm = ({ data }) => {
  const { register, handleSubmit, errors, setValue } = useForm();
  console.log("InsuranceForm", data);
  useEffect(() => {
    if (data && data.ocr_value) {
      Object.keys(data.ocr_value).forEach((key) => {
        setValue(key, data.ocr_value[key]);
      });
    }
  }, [data, setValue]);

  return (
    <div className={Styles.InsuranceFormContainer}>
      <div className="mb-5">
        <label htmlFor="">Insurance Provider</label>
        <input
          type="text"
          readOnly
          {...register("insurance_provider_name")}
          className="w-full"
        />
      </div>
      <div className="flex md:gap-14 gap-5 md:flex-row flex-col mb-5">
        <div className="md:w-1/2 w-full">
          <label htmlFor="">Member Id</label>
          <input
            type="text"
            readOnly
            {...register("member_id")}
            className="w-full"
          />
        </div>
        <div className="md:w-1/2 w-full">
          <label htmlFor="">Group Number</label>
          <input
            type="text"
            readOnly
            {...register("group_id")}
            className="w-full"
          />
        </div>
      </div>
      <div className="flex md:gap-14 gap-5 md:flex-row flex-col mb-5">
      <div className="md:w-1/2 w-full">
          <label htmlFor="">Plan Start Date</label>
          <input
            type="text"
            readOnly
            {...register("plan_start_date")}
            className="w-full"
          />
        </div>
        <div className="md:w-1/2 w-full">
          <label htmlFor="">Plan End Date</label>
          <input
            type="text"
            readOnly
            {...register("plan_end_date")}
            className="w-full"
          />
        </div>
      </div>
      <div className="flex md:gap-14 gap-5 md:flex-row flex-col mb-5">
      <div className="md:w-1/2 w-full">
          <label htmlFor="">First Name</label>
          <input
            type="text"
            readOnly
            {...register("subscriber_first_name")}
            className="w-full"
          />
        </div>
        <div className="md:w-1/2 w-full">
          <label htmlFor="">Last Name</label>
          <input
            type="text"
            readOnly
            {...register("subscriber_last_name")}
            className="w-full"
          />
        </div>
      </div>
      <div className="flex md:gap-14 gap-5 md:flex-row flex-col mb-5">
      <div className="md:w-1/2 w-full">
          <label htmlFor="">Date Of Birth</label>
          <input
            type="text"
            readOnly
            {...register("date_of_birth")}
            className="w-full"
          />
        </div>
        <div className="md:w-1/2 w-full">
          <label htmlFor="">Payer ID</label>
          <input
            type="text"
            readOnly
            {...register("payer_id")}
            className="w-full"
          />
        </div>
      </div>
      <div className="w-full mb-5">
        <label htmlFor="">Address</label>
        <input
          type="text"
          readOnly
          {...register("address")}
          className="w-full"
        />
      </div>
    </div>
  );
};

export default InsuranceForm;
