import React, { useEffect } from "react";
import pdfIcon from "../../assets/pdfIcon.png";
import styles from "./ImagePreview.module.scss";

const ImagePreview = ({ data }) => {
  useEffect(() => {
    console.log("ImagePreview", data);
  }, [data]);

  const isImageType = (type) => {
    return ["image/jpeg", "image/png", "image/heic"].includes(type);
  };

  const renderFilePreview = (item) => {
    console.log("item", item);
    const itemType = item.type;

    // Check if the item is a Blob and if it doesn't have a type
    if (item instanceof Blob && !itemType) {
      console.error("Blob provided without a MIME type.");
      return (
        <div className={styles.errorWrapper}>Unable to preview this file.</div>
      );
    }

    if (isImageType(itemType)) {
      return (
        <img
          className={`${styles.previewImage}`}
          src={URL.createObjectURL(item)}
          alt={item.name}
        />
      );
    } else {
      // Display a link for unsupported file types
      return (
        <div className={styles.unsupportedFile}>
          <a
            href={URL.createObjectURL(item)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={pdfIcon} alt="file-icon" className={styles.fileIcon} />
            <span>{item.name}</span>
          </a>
        </div>
      );
    }
  };

  return (
    <div className={styles.ImagePreviewContainer}>
      {data &&
        data.length > 0 &&
        data.map((item, index) => {
          return (
            <div className={styles.previewWrapper} key={index}>
              {renderFilePreview(item)}
            </div>
          );
        })}
    </div>
  );
};

export default ImagePreview;
