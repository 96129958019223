import React, { useEffect, useState } from "react";
import { Modal } from "@material-ui/core";
import styles from "./UserFeedback.module.scss";
import close from "../../assets/close.svg";
import { useForm } from "react-hook-form";
import clevertap from "clevertap-web-sdk";

const UserFeedback = ({ openUserFeedback, closeUserFeedback }) => {
  const [open, setOpen] = useState(openUserFeedback);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const feedbackOptions = ["Awesome", "Average", "Good", "Poor"];

  const selectedFeedback = watch("feedback"); // Watch the feedback field to be able to reactively update the UI

  useEffect(() => {
    setOpen(openUserFeedback);
  }, [openUserFeedback]);

  useEffect(() => {
    register("feedback"); // Register feedback field
  }, [register]);

  const handleClose = () => {
    setOpen(false);
    if (closeUserFeedback) {
      closeUserFeedback();
    }
  };

  const handleSelectFeedback = (feedback) => {
    setValue("feedback", feedback);
  };

  const onSubmit = (data) => {
    clevertap.event.push("quick_capture_app_feedback_submit");
    console.log(data);
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
    };
    fetch("/api/feedback", requestOptions).then((res) => {
      console.log("feedback", res);
      window.location.reload();
    });
    // Handle form submission
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={styles.UserFeedbackContainer}
      disableEscapeKeyDown={true}
      hideBackdrop={true}
    >
      <div className="max-w-[600px] bg-[#FFF] rounded-[20px] max-h-[80vh] w-full relative outline-none">
        <div className="flex items-center justify-between bg-[#E8EDF9] py-6 pl-11 pr-7 rounded-t-[20px]">
          <div className={styles.header}>SPRY’s Quick Capture</div>
          <div>
            {" "}
            <img
              src={close}
              alt=""
              className="w-[14px] h-[14px] flex justify-end cursor-pointer"
              onClick={handleClose}
            />
          </div>
        </div>
        <div className="mt-7 px-10 mb-[150px] overflow-y-scroll">
          <div className="flex md:gap-14 gap-5 md:flex-row flex-col mb-5">
            <div className="md:w-1/2 w-full">
              <label htmlFor="">First Name</label>
              <input
                type="text"
                {...register("first_name")}
                className="w-full"
              />
            </div>
            <div className="md:w-1/2 w-full">
              <label htmlFor="">Last Name</label>
              <input
                type="text"
                {...register("last_name")}
                className="w-full"
              />
            </div>
          </div>
          <div className="mt-7 mb-7">
            <div className="mb-3">
              <label>Rate Spry Quick Capture</label>
            </div>
            <div className={styles.chipsContainer}>
              <div className="flex gap-[10px]">
                {feedbackOptions.map((option) => (
                  <button
                    key={option}
                    type="button"
                    onClick={() => handleSelectFeedback(option)}
                    className={`${styles.chip} ${
                      selectedFeedback === option ? styles.chipSelected : ""
                    }`}
                  >
                    {option}
                  </button>
                ))}
              </div>
              {errors.feedback && (
                <p className="error">{errors.feedback.message}</p>
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="mb-2">
              <label htmlFor="comments">Comments</label>
            </div>
            <textarea
              {...register("comments")}
              id="comments"
              className="outline-none w-full h-[100px]"
            ></textarea>
          </div>
        </div>
        <div
          className={styles.actionsContainer}
          style={{
            position: "absolute",
            bottom: "0",
            width: "100%",
            // borderTop: "1px solid black",
          }}
        >
          <div className="flex justify-end gap-5 mr-12 py-6 ">
            <button
              className={`${styles.cancel} py-4 px-5`}
              onClick={() => {
                window.location.reload();
              }}
            >
              Cancel
            </button>
            <button
              className={`${styles.submit} py-2 px-5`}
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UserFeedback;
