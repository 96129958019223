import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import info from "../../assets/InofIcon.svg";
import pdfIcon from "../../assets/pdfIcon.png";
import deleteIcon from "../../assets/deleteIcon.svg";
import styles from "./FileUploader.module.scss";
import uploadIcon from "../../assets/uploadIcon.svg";
import CameraAccess from "../../Components/CameraAccess/CameraAccess";
import scanIcon from "../../assets/scan.svg";
import QuickCapture from "../../Components/QuickCapture/QuickCapture";
import CapturedResult from "../../Components/CapturedResult/CapturedResult";
import clevertap from "clevertap-web-sdk";

const FileUploader = ({ onUpload, onOCRResult }) => {
  const [error, setError] = useState(null); // State to store error messages
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [openCameraAccess, setOpenCameraAccess] = useState(false);
  const [openQuickCapture, setOpenQuickCapture] = useState(false); // State to track if QuickCapture is open
  const [isResult, setIsResult] = useState(false); // State to track if result is available
  const [result, setResult] = useState(null); // State to store result
  const [loading, setLoading] = useState(false);
  const [dragging, setDragging] = useState(false); // State to track if user is dragging over
  const [uploadedFileList, setUploadedFileList] = useState([]); // State to store uploaded images

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    let files = Array.from(e.dataTransfer.files);
    console.log(files);
    // Filter out unwanted file types
    files = files.filter((file) =>
      ["image/jpeg", "image/png", "application/pdf", "image/heic"].includes(
        file.type
      )
    );

    processFiles(files);
  };

  useEffect(() => {
    clevertap.event.push("QuickCapture_App_Home_Visit");
  }, []);

  const processFiles = (files) => {
    const previews = files.map((file) => URL.createObjectURL(file));
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    setFilePreviews((prevPreviews) => [...prevPreviews, ...previews]);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const previews = files.map((file) => URL.createObjectURL(file));
    console.log(previews);

    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    setFilePreviews((prevPreviews) => [...prevPreviews, ...previews]);
  };
  const handleUpload = () => {
    clevertap.event.push("quick_capture_app_image_upload");
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");

    const formdata = new FormData();
    selectedFiles.forEach((file) => {
      setUploadedFileList((prevList) => [...prevList, file]);
      formdata.append("files", file, file.name); // Here, 'files' is used as the field name for all files
    });
    // setIsResult(true);
    // setLoading(false)
    // setResult(result);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("/api/perform_ocr", requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Failed to process the image. Please try again.");
        }
      })
      .then((result) => {
        console.log(result);
        onOCRResult(result);
        setIsResult(true);
        setResult(result);
      })
      .catch((error) => {
        setError("An error occurred. Please try again.");
      })
      .finally(() => setLoading(false)); // Set loading to false after the API call is complete

    // setSelectedFiles([]);
  };

  const deleteFile = (index) => {
    setSelectedFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
    setFilePreviews((prevPreviews) => {
      const newPreviews = [...prevPreviews];
      newPreviews.splice(index, 1);
      return newPreviews;
    });
  };

  return (
    <div className="">
      <Header logoPath="/" />
      <div className="md:hidden flex mt-4 mr-2 justify-end">
        <button
          className={styles.exploreButton}
          onClick={() =>{
            clevertap.event.push("quick_capture_app_Explore_Other_Features_Clicked");
            window.location.href = "https://www.sprypt.com/ai-corner";
          }
          }
        >
          Explore other features
        </button>
      </div>
      {!isResult && (
        <>
          <div
            className="text-center mt-[68px]"
            style={{
              color: "#282828",
              textAlign: "center",
              fontFamily: "Recoleta",
              fontSize: "48px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "48px",
            }}
          >
            SPRY Quick Capture: Your One and Only Solution to Manual Entry Woes
          </div>
          <div className="text-center mt-1">
            Efficiently Extract Data from Your Insurance Card
          </div>
          <div
            id="drganddrop"
            className={`rounded-[20px] border pb-8 pt-14 px-8 mx-auto my-0 flex flex-col justify-center items-center border-dashed mt-10 md:max-w-[60%] max-w-full ${
              dragging ? "bg-gray-200" : ""
            }`}
            style={{
              boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.12)",
            }}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            {!selectedFiles.length && (
              <>
                <div className="hidden md:block">
                  <button
                    className={styles.scanCardBtn}
                    onClick={() => {
                      clevertap.event.push("quick_capture_app_Scan_Card_Clicked");
                      setOpenCameraAccess(true);
                    }}
                  >
                    <img src={scanIcon} alt="" className="mr-2" />
                    <div>Scan your card</div>{" "}
                  </button>
                </div>
                <div className={`${styles.orText} mb-3 mt-5 hidden md:block`}>
                  Or
                </div>
              </>
            )}
            <div>
              <input
                type="file"
                onChange={handleFileChange}
                accept=".jpg, .jpeg, .png, .pdf, .heic"
                multiple
                style={{ display: "none" }}
                id="fileInput"
              />
              <button
                onClick={() => {
                  clevertap.event.push("quick_capture_app_Upload_Image_Clicked");
                  document.getElementById("fileInput").click();
                }}
                className="flex items-center rounded-2xl py-[10px] px-14 gap-2 min-w-[300px]"
                style={{
                  border: "1px solid #788CFC",
                }}
              >
                <img src={uploadIcon} className="h-10 w-10" alt="" />
                <div className={styles.uploadBtn}>Upload image</div>
              </button>
              <div className={`${styles.dragAndDropText} mt-5`}>
                Drag and drop image here
              </div>
            </div>
            {selectedFiles.length > 0 && (
              <>
                <div className={` mt-7 mb-12 ${styles.uploadedFilesHeader}`}>
                  Uploaded Document
                </div>
                <div className="flex flex-col gap-6 max-w-lg">
                  {selectedFiles.map((file, index) => (
                    <div
                      key={index}
                      className="bg-[#F0F5FC] border-[#4A4A4A] rounded-xl px-7 py-4 flex gap-3"
                    >
                      {file.type === "application/pdf" ? (
                        <img
                          src={pdfIcon}
                          alt="PDF Icon"
                          style={{ width: "60px", height: "60px" }}
                        />
                      ) : (
                        <img
                          src={filePreviews[index]}
                          alt="Preview"
                          style={{ width: "60px", height: "60px" }}
                        />
                      )}
                      <div>
                        <div className="flex justify-between gap-8 items-center">
                          <div>
                            {" "}
                            <div className={styles.fileName}>{file.name}</div>
                            <div className={styles.fileSize}>
                              ({Math.round(file.size / 1024)} KB)
                            </div>
                          </div>
                          <div>
                            <img
                              src={deleteIcon}
                              className="cursor-pointer"
                              alt=""
                              onClick={() => deleteFile(index)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            {selectedFiles.length > 0 && (
              <div className="flex justify-center mt-6">
                <button
                  className={`${styles.captureData} px-14 py-3`}
                  onClick={handleUpload}
                >
                  Capture Data
                </button>
              </div>
            )}
          </div>
          <div className="flex justify-center">
            <div className="rounded-xl border px-6 py-3 mt-5 flex items-center gap-2 bg-[#F7F7F7] border-[#A1A1A1]">
              <img src={info} alt="" />
              <div className="opacity-60">
                We're fully HIPAA compliant, safeguarding your privacy and
                sensitive data at every step.
              </div>
            </div>
          </div>
          {openCameraAccess && (
            <CameraAccess
              openCameraAccess={openCameraAccess}
              closeCameraAccess={() => {
                setOpenCameraAccess(false);
              }}
              allowedCameraAccess={() => {
                setOpenQuickCapture(true);
              }}
            />
          )}
          {openQuickCapture && (
            <QuickCapture
              onOCRResult={(result) => {
                setIsResult(true);
                setResult(result);
              }}
              isLoading={(loading) => {
                setLoading(loading);
              }}
              openQuickCapture={openQuickCapture}
              closeQuickCapture={() => {
                setOpenQuickCapture(false);
              }}
              updatedFileList={(fileList) => {
                setUploadedFileList((prevList) => [...prevList, fileList]);
              }}
            />
          )}
        </>
      )}
      {isResult && (
        <CapturedResult uploadedFileList={uploadedFileList} data={result} />
      )}
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              border: "8px solid #f3f3f3",
              borderRadius: "50%",
              borderTop: "8px solid #3498db",
              width: "50px",
              height: "50px",
              animation: "spin 2s linear infinite",
            }}
          ></div>
        </div>
      )}
      {error && (
        <div
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 2000, // Ensure the error message and overlay are on top
          }}
        >
          <div
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
              maxWidth: "80%",
            }}
          >
            <div
              style={{
                marginBottom: "10px",
                fontSize: "18px",
                color: "#D32F2F",
              }}
            >
              {error}
            </div>
            <button
              onClick={() => {
                window.location.reload();
              }}
              style={{
                padding: "10px 20px",
                backgroundColor: "#1976D2",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "16px",
              }}
            >
              Retry
            </button>
          </div>
        </div>
      )}
      {!isResult && (
        <div className="bg-[#1F203F] p-4 md:p-20 text-[#fff] mt-14">
          <div
            className="mb-6 text-[#fff] text-[40px] font-medium"
            style={{
              fontFamily: "Recoleta",
            }}
          >
            How to Use Spry Quick Capture
          </div>
          <div
            className="mb-9 text-[#fff] text-[18px] font-normal"
            style={{
              fontFamily: "Manrope",
            }}
          >
            Breathe Easy With Flawless Insurance Entries, Dotting the i’s and
            Crossing the t’s
          </div>
          <div className="w-full flex gap-10 md:flex-row flex-col">
            <div className="md:w-1/3 p-8 bg-[#4c4d65] rounded-[10px] w-full">
              <div className="w-[56px] h-[56px] mb-6 flex items-center justify-center bg-[#FDDA6F] rounded-[50%]">
                <div
                  className="text-black text-[32px]"
                  style={{
                    fontFamily: "Manrope",
                  }}
                >
                  1
                </div>
              </div>
              <div
                className="text-2xl font-medium mb-4"
                style={{
                  fontFamily: "Recoleta",
                }}
              >
                Capture Insurance Card:
              </div>
              <div>Snap a photo or submit a visual of the insurance card.</div>
            </div>
            <div className="md:w-1/3 p-8 bg-[#4c4d65] rounded-[10px] w-full">
              <div className="w-[56px] mb-6 h-[56px] flex justify-center items-center bg-[#FDDA6F] rounded-[50%]">
                <div
                  className="text-black text-[32px]"
                  style={{
                    fontFamily: "Manrope",
                  }}
                >
                  2
                </div>
              </div>
              <div
                className="text-2xl font-medium mb-4"
                style={{
                  fontFamily: "Recoleta",
                }}
              >
                Pinpoint Accuracy:
              </div>
              <div>
                Capture scans the visual data with unmatched precision, leaving
                no detail behind.
              </div>
            </div>
            <div className="md:w-1/3 p-8 bg-[#4c4d65] rounded-[10px] w-full">
              <div className="w-[56px] h-[56px] flex items-center justify-center bg-[#FDDA6F] rounded-[50%] mb-6">
                <div
                  className="text-black text-[32px] font-medium"
                  style={{
                    fontFamily: "Manrope",
                  }}
                >
                  3
                </div>
              </div>
              <div
                className="text-2xl font-medium mb-4"
                style={{
                  fontFamily: "Recoleta",
                }}
              >
                Automatic Data Entry:{" "}
              </div>
              <div>
                Watch our Quick Capture automatically populate your system with
                the extracted insurance information.
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploader;
