import React, { useState } from 'react';
import FileUploader from './Pages/FileUploader/FileUploader';

const App = () => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [ocrResult, setOcrResult] = useState(null); // Store the OCR result here

  const handleFileUpload = (file) => {
    setUploadedFile(file);
  };

  const handleOCRResult = (result) => {
    setOcrResult(result); // Set the OCR result when received
  };

  return (
    <div className=''>
      <FileUploader onUpload={handleFileUpload} onOCRResult={handleOCRResult} />
    </div>
  );
};

export default App;
