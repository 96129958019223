import React, { useEffect, useState } from "react";
import styles from "./CapturedResult.module.scss";
import retryIcon from "../../assets/retryIcon.svg";
import warnIcon from "../../assets/warn.svg";
import InsuranceForm from "../InsuranceForm/InsuranceForm";
import ImagePreview from "../ImagePreview/ImagePreview";
import UserFeedback from "../UserFeedback/UserFeedback";
import clevertap from "clevertap-web-sdk";

const CapturedResult = ({ data, uploadedFileList }) => {
  const [userFeedbackPopUp, setUserFeedbackPopUp] = useState(false);
  console.log("CapturedResult", data);
  useEffect(() => {
    clevertap.event.push("quick_capture_app_data_extraction_screen_visit");
  }, []);
  return (
    <div className={`${styles.CapturedResultContainer} md:px-[76px] px-3`}>
      <div className="flex justify-between mt-9 flex-col md:flex-row">
        <div className={styles.header}>Here is the extracted data</div>
        <div className="flex gap-5">
          <button className={styles.retryBtn}>
            <img src={retryIcon} alt="" />
            <div
              onClick={() => {
                clevertap.event.push(
                  "quick_capture_app_quick_capture_retry"
                );
                window.location.reload();
              }}
            >
              Retry Capture
            </div>
          </button>
          <button
            className={styles.completeBtn}
            onClick={() => {
              clevertap.event.push(
                "quick_capture_app_quick_capture_complete"
              );
              setUserFeedbackPopUp(true);
            }}
          >
            Complete
          </button>
        </div>
      </div>
      <div className="flex rounded-xl border-[#C2A46B] bg-[#FFF9C7] max-w-max px-5 py-3 items-center gap-2 mb-10 mt-3">
        <img src={warnIcon} alt="" />
        <div className={styles.warnText}>
          Bear in mind that data accuracy may vary due to card conditions and
          lighting.
        </div>
      </div>
      <div className="flex gap-[100px] md:flex-row flex-col">
        <div className="w-full md:w-[40%]">
          <div className="text-left mt-6 mb-6">Image Preview</div>
          <ImagePreview data={uploadedFileList} />
        </div>
        <div className="w-full md:w-[60%]">
          <div className="text-left mt-6 mb-6">Captured Data</div>
          <InsuranceForm data={data} />
        </div>
      </div>
      {userFeedbackPopUp && (
        <UserFeedback
          openUserFeedback={userFeedbackPopUp}
          closeUserFeedback={() => {
            setUserFeedbackPopUp(false);
          }}
        />
      )}
    </div>
  );
};

export default CapturedResult;
