import { Modal } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styles from "./QuickCapture.module.scss";
import close from "../../assets/close.svg";
import Webcam from "react-webcam";
import deleteIcon from "../../assets/deleteIcon.svg";
import clevertap from "clevertap-web-sdk";

const QuickCapture = ({ openQuickCapture, closeQuickCapture, onOCRResult,updatedFileList }) => {
  const [open, setOpen] = useState(false);
  const [captureType, setCaptureType] = useState("front");
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const webcamRef = useRef(null);
  const [highlight, setHighlight] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleClose = () => {
    clevertap.event.push("quick_capture_app_closed");
    setOpen(false);
    closeQuickCapture();
  };

  useEffect(() => {
    if (openQuickCapture) {
      setOpen(true);
    }
  }, [openQuickCapture]);

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (captureType === "front") {
      clevertap.event.push("quick_capture_app_front_image_captured");
      setFrontImage(imageSrc);
      setCaptureType("back");
      setHighlight("front"); // Set highlight to front
    } else {
      clevertap.event.push("quick_capture_app_back_image_captured");
      setBackImage(imageSrc);
      setCaptureType("front");
      setHighlight("back"); // Set highlight to back
    }
  };

  const handleUpload = () => {
    clevertap.event.push("quick_capture_app_data_captured");
    setIsLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    const formdata = new FormData();

    if (frontImage) {
      const frontBlob = dataURItoBlob(frontImage);
      updatedFileList(frontBlob);
      formdata.append("files", frontBlob, "front.jpg");
    }

    if (backImage) {
      const backBlob = dataURItoBlob(backImage);
      updatedFileList(backBlob);
      formdata.append("files", backBlob, "back.jpg");
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("/api/perform_ocr", requestOptions)
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        throw new Error("Failed to process the image. Please try again.");
      }
    })
    .then((result) => {
      onOCRResult(result);
      closeQuickCapture();
    })
    .catch((error) => {
      console.log("error", error);
      setError(error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  // Convert base64 data component to raw binary data held in a string
  function dataURItoBlob(dataURI) {
    // Assume all data URIs are base64 encoded in this context
    const byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  const handleOpen = () => {};
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={styles.QuickCapturePopUpContainer}
      disableEscapeKeyDown={true}
      hideBackdrop={true}
    >
      <div className="max-w-[930px] bg-[#FFF] rounded-[20px] max-h-[100vh] w-full relative outline-none">
        <div className="flex items-center justify-between bg-[#E8EDF9] py-6 pl-11 pr-7 rounded-t-[20px]">
          <div className={styles.header}>SPRY’s Quick Capture</div>
          <div>
            {" "}
            <img
              src={close}
              alt=""
              className="w-[14px] h-[14px] flex justify-end cursor-pointer"
              onClick={handleClose}
            />
          </div>
        </div>
        <div className={`${styles.body} overflow-y-scroll h-100% max-h-[80vh]`}>
          <div className={styles.subHeader}>
          Kindly Scan both the front and back of the insurance card.
          </div>
          {/* <div className={styles.desc}>
            ligula quis scelerisque pretium, leo libero iaculis orci, ac egestas
            odio ipsum at
          </div> */}
          <div className="">
            <Webcam
              ref={webcamRef}
              width={432}
              height={338}
              screenshotFormat="image/jpeg"
              className="rounded-[20px] mx-auto my-0"
              style={
                {
                  transform: "scaleX(-1)",
                }
              }
            />
          </div>
          <div className="flex justify-center items-center flex-col mt-3">
            <div className={styles.stepsContainer}>
              <div>
                <div
                  className={`border-[#415280] border w-[34px] h-[34px] rounded-[50%] flex items-center justify-center ${
                    highlight === "front" ? styles.highlighted : ""
                  } ${
                    frontImage
                      ? "bg-[#415280] text-white"
                      : "bg-white text-[#415280]"
                  }`}
                >
                  1
                </div>
              </div>
              <div className={styles.line}></div>
              <div>
                <div
                  className={`border-[#415280] border w-[34px] h-[34px] rounded-[50%] flex items-center justify-center ${
                    highlight === "back" ? styles.highlighted : ""
                  } ${
                    backImage
                      ? "bg-[#415280] text-white"
                      : "bg-white text-[#415280]"
                  }`}
                >
                  2
                </div>
              </div>
            </div>
            <div className={styles.stepsContainer}>
              <div>
                <div>Front</div>
              </div>
              <div>
                <div>Back</div>
              </div>
            </div>
          </div>

          {(!frontImage || !backImage) && (
            <div className="flex justify-center mt-4">
              <button
                className={`${styles.captureImage} py-2 px-5`}
                onClick={handleCapture}
              >
                Capture image
              </button>
            </div>
          )}
          {/* <div className="text-center mt-4">Captured Image</div> */}
          {/* <div className="max-w-[400px] w-full mx-auto my-0 flex flex-col gap-5">
            {frontImage && (
              <div className="bg-[#F0F5FC] border-[#4A4A4A] rounded-xl px-7 py-4 flex gap-3">
                <img
                  src={frontImage}
                  alt="Front"
                  style={{ width: "60px", height: "60px" }}
                />
                <div className="flex flex-col justify-between">
                  <div className={styles.fileName}>Front Image</div>
                </div>
              </div>
            )}

            {backImage && (
              <div className="bg-[#F0F5FC] border-[#4A4A4A] rounded-xl px-7 py-4 flex gap-3">
                <img
                  src={backImage}
                  alt="Back"
                  style={{ width: "60px", height: "60px" }}
                />
                <div className="flex flex-col justify-between">
                  <div className={styles.fileName}>Back Image</div>
                </div>
              </div>
            )}
          </div> */}
        </div>
        <div
          className={styles.actionsContainer}
          style={{
            position: "absolute",
            bottom: "0",
            width: "100%",
            // borderTop: "1px solid black",
          }}
        >
          <div className="flex justify-end gap-5 mr-12 py-6">
            <button className={`${styles.cancel} py-4 px-5`} onClick={
              handleClose
            }>Cancel</button>
            <button
              className={`${styles.submit} py-2 px-5`}
              onClick={handleUpload}
            >
              Capture Data
            </button>
          </div>
        </div>
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.8)", // This is a white overlay with some transparency
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000, // Ensure the loader is on top
            }}
          >
            {/* You can replace this with a spinner or any other loading graphic */}
            <div>Loading...</div>
          </div>
        )}
        {error && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black overlay
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000, // Ensure the error message and overlay are on top
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.9)", // Semi-transparent white background for error container
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Gentle shadow
                textAlign: "center",
                maxWidth: "80%", // Ensure the error container fits on the screen
              }}
            >
              <div
                style={{
                  marginBottom: "10px",
                  fontSize: "18px",
                  color: "#D32F2F", // Red color for the error message
                }}
              >
                {error}
              </div>
              <button
                onClick={() => {
                  window.location.reload();
                }}
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#1976D2", // Blue color for the button
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontSize: "16px",
                }}
              >
                Retry
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default QuickCapture;
